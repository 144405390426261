import Vue from 'vue';

const filter = Vue.observable({
    /**
    * Lista de proveedores
     */
    list: [],
    /**
    * Proveedor seleccionado
     */
    selected: {},
    /**
    * Fecha de última actualización del filtro
    */
    lastUpdated: null,
});


/**
 * Controla si la fecha de última actualización es mayor a 6 horas.
 */
const expiredData = () => {
    const dateNow = new Date().getTime() / 1000;
    const dateTarget = dateNow - ( 4 * 60 * 60 );


    if(!filter.lastUpdated){
        return true;
    }

    if(filter.lastUpdated <= dateTarget){
        return true;
    }

    return false;
}

/**
 * Actualiza la lista de proveedores
 * @param {*} from origen de datos (movies o series)
 * @param {*} data array de datos a procesar
 */
const update = (from, data) => {

    if(from === 'MOVIES'){
        let flatData = [];
        let resultData = [];

        data.map((p) => {
            if(p.movies){
                flatData = [...flatData, ...p.movies];
            }
            return;
        });

        resultData = flatData.filter((p,index) => {
            return flatData.findIndex(q => q.channel.id === p.channel.id) === index;
        })

        filter.list = resultData;
    }
    if(from === 'SERIES'){
        let flatData = [];
        let resultData = [];

        data.map((p) => {
            if(p.series){
                flatData = [...flatData, ...p.series];
            }
            return;
        });

        resultData = flatData.filter((p,index) => {
            return flatData.findIndex(q => q.channel.id === p.channel.id) === index;
        })

        filter.list = resultData;
    }
}

const getProviderById = (providerId) => {
    const result = filter.list.filter(p => p.channel.id === parseInt(providerId));
    return result.length > 0 ? result.shift() : null;
}


export { filter, update, getProviderById };
