<template>
  <div class="movies">
    <template v-if="banners && banners.length > 0">
      <SliderFull :banners="banners" class="banner-vod" />
    </template>

    <header class="main-header">
      <div class="row row-page-top flex-scroll">
        <h1 class="poster-rail--title section-title">Películas</h1>

        <FiltersScroll :items="filterGenres" :widthControls="230">
          <div class="filters-toolbar">
            <Select
              cssClass="dropdown-channel"
              :list="genresNameFilter"
              :onSelect="addFilter"
              :showFullLoader="showFullLoader"
              keyField="idCatalog"
              textField="name"
              title="Género"
              searchTitle="Filtrar por género:"
            />
          </div>
        </FiltersScroll>
      </div>
    </header>

    <FilterProvider
      :items="filterProvider"
      @selectProvider="onSelectProvider"
    />

    <main class="wrapper container-fluid virtual-scroll" role="main">
      <template v-for="(genero, index) of orderedGenres">
        <template v-if="haveItems(genero.movies)">
          <Slider
            :key="genero.idCatalog"
            :data="genero.movies"
            :title="changeVariusGenre(genero.name)"
            :url="
              '/peliculas/' +
              genero.idCatalog +
              '/' +
              genero.name +
              (selectedProvider ? '/' + selectedProvider : '')
            "
          >
            <template slot-scope="itemslider">
              <CardVOD
                v-for="(item, index) of itemslider.data"
                :key="item.idAsset"
                :data="item"
                componentOrigin="VOD"
                :sliderId="'slider-peliculas'"
                :sliderTitle="'Películas'"
                :cardId="'card-' + item.idAsset"
              />
            </template>
          </Slider>
        </template>
      </template>

      <Slider
        v-show="continueWatchingRailTemp.length > 0"
        :key="'continuar_viendo'"
        :title="'Continuar viendo'"
        :icon="'zmdi-movie-alt'"
        data-testid="rails"
        :data="continueWatchingRailTemp"
      >
        <template slot-scope="itemslider">
          <CardVOD
            v-for="item of itemslider.data"
            :key="item.idAsset"
            :data="item"
            :sliderId="'slider-continuar_viendo'"
            :sliderTitle="'Continuar viendo'"
            :cardId="'card-' + item.idAsset"
          />
        </template>
      </Slider>

      <Slider
        v-show="watchAgainRailTemp.length > 2"
        :key="'volver_aver'"
        :title="'Volver a ver'"
        :icon="'zmdi-movie-alt'"
        data-testid="rails"
        :data="watchAgainRailTemp"
      >
        <template slot-scope="itemslider">
          <CardVOD
            v-for="item of itemslider.data"
            :key="item.idAsset"
            :data="item"
            :sliderId="'slider-volver_aver'"
            :sliderTitle="'Volver a ver'"
            :cardId="'card-' + item.idAsset"
          />
        </template>
      </Slider>

      <LoaderScroll v-show="isScroll" />
    </main>

    <Loader v-if="showFullLoader" />
  </div>
</template>

<script>
import _ from "lodash";
import * as providers from "@/observables/filterProvider";
import defaultBannerImage from "@/assets/images/banner-default.png";

const CANT_INICIAL = 3;
const LIMIT_ITEMS = 0;

export default {
  name: "Movies",

  components: {
    CardVOD: () => import("@/components/Card-VOD.vue"),
    FiltersScroll: () => import("@/components/Filters-scroll.vue"),
    Loader: () => import("@/components/Loader.vue"),
    LoaderScroll: () => import("@/components/Loader-scroll.vue"),
    Slider: () => import("@/components/Slider.vue"),
    Select: () => import("@/components/Select.vue"),
    FilterProvider: () => import("@/components/FilterProvider.vue"),
    SliderFull: () => import("@/components/SliderFull.vue"),
  },

  data() {
    return {
      banners: [],
      dom: {
        gridContainer: null,
      },
      CANT_CARDS: 14,
      generos: [], //Todos los géneros
      tmpList: [], //Géneros que estoy mostrando
      filterGenres: [], //Géneros del filtro
      filterGenresText: "",
      isScroll: false,
      showFullLoader: true,
      isFiltered: false, //Indica si la lista de géneros es filtrada por el usuario o no
      filterProvider: [],
      selectedProvider: null,
      continueWatchingRail: [],
      watchAgainRail: [],
      continueWatchingRailTemp: [],
      watchAgainRailTemp: [],
    };
  },

  created() {
    let self = this;

    self.changeCursor(true);

    self.getGenresAndMovies();

    // telecentro.tplay.core.favoritos.traerFavoritos(function(favs) {

    //     telecentro.tplay.core.peliculas.todosLosGeneros(function(generos) {
    //         let countLoaded = 0;

    //         for(let i = 0; i < CANT_INICIAL; i++) {

    //             telecentro.tplay.core.peliculas.peliculasPorGenero(generos[i].idCatalog, function(peliculas) {
    //                 countLoaded++;
    //                 generos[i].movies = peliculas;

    //                 if(countLoaded === CANT_INICIAL || generos.length == countLoaded)
    //                 {
    //                         self.generos = generos;

    //                         self.tmpList = self.generos.slice(0, CANT_INICIAL);

    //                         self.showFullLoader = false;

    //                         self.changeCursor(false);
    //                 }
    //             });
    //         }

    //     });

    // });
  },

  mounted() {
    let self = this;

    telecentro.tplay.core.biz.ncf.getBanners(
      "movies",
      (_banners) => {
        // console.log('movie', _banners);
        if (
          _banners[0].imageUrl ===
            "https://source.unsplash.com/random/1920x828?003" ||
          _banners[0].imageUrl === undefined
        ) {
          _banners[0].imageUrl = defaultBannerImage;
        }
        self.banners = _banners;
      },
      (_error) => {
        self.$bus.$emit("show-toast", "error", _error.message);
      }
    );

    telecentro.tplay.core.biz.ncf.getRails("movies", (_rails) => {
      if (_rails.rails) {
        __.forEach(_rails.rails, (item) => {
          const railConfig = item;
          var { railId } = railConfig;
          if (railId.toLowerCase() === "continuewatching") {
            self.continueWatching();
          } else if (railId.toLowerCase() === "watchagain") {
            self.watchAgain();
          }
        });
      }
    });

    this.dom.gridContainer = document.querySelector(".virtual-scroll");

    document.addEventListener("scroll", this.onScroll, true);
    this.$bus.$on("delete-filter", this.ClearFIlter);

    this.$bus.$on("delete-filter-scroll", function (index) {
      self.deleteFilter(index);
    });
  },

  computed: {
    genresNameFilter: function () {
      return this.findBy(
        this.generos,
        this.filterGenresText.toUpperCase(),
        "name"
      );
    },

    orderedGenres() {
      const self = this;
      let tmpOrderedGenres = [];

      if (self.selectedProvider) {
        tmpOrderedGenres = self.tmpList.map((p) => {
          const filt = p.movies.filter(
            (q) => q.channel.id === self.selectedProvider
          );
          return { ...p, movies: filt };
        });
        self.watchAgainRailTemp = self.watchAgainRail.filter(
          (q) => q.channel.id === self.selectedProvider
        );
        self.continueWatchingRailTemp = self.continueWatchingRail.filter(
          (q) => q.channel.id === self.selectedProvider
        );
      } else {
        tmpOrderedGenres = self.tmpList;
        self.watchAgainRailTemp = self.watchAgainRail;
        self.continueWatchingRailTemp = self.continueWatchingRail;
      }

      const recomendadas = tmpOrderedGenres.filter(
        (genero) => genero.name == "Recomendadas"
      );
      const novedades = tmpOrderedGenres.filter(
        (genero) => genero.name == "Novedades"
      );
      const temp = tmpOrderedGenres.filter(
        (genero) => genero.name != "Recomendadas" && genero.name != "Novedades"
      );
      const otrosGenerosOrdered = _.orderBy(
        temp,
        (p) => p["name"].toLowerCase(),
        "name"
      );

      return [...recomendadas, ...novedades, ...otrosGenerosOrdered];
    },
  },

  methods: {
    getGenresAndMovies() {
      const self = this;
      telecentro.tplay.core.favoritos.traerFavoritos(() => {
        // Consulto en el core todos los generos de películas
        telecentro.tplay.core.peliculas.todosLosGeneros((result) => {
          let generos = result;
          generos.forEach((genre, k) => {
            if (genre.idCatalog && !genre.movies) {
              // Traigo las películas del género
              telecentro.tplay.core.peliculas.peliculasPorGeneroLimit(
                genre.idCatalog,
                LIMIT_ITEMS,
                (peliculas) => {
                  genre.movies = peliculas;
                  /*
                   * como esto funciona con callbacks,
                   * debo saber si llegúe al último elemento del array de generos
                   * para finalizar el proceso
                   */
                  if (k === generos.length - 1) {
                    self.initRender(generos);
                  }
                }
              );
            } else if (k === generos.length - 1) {
              self.initRender(generos);
            }
          });
        });
      });
    },
    initRender(generos) {
      const self = this;

      providers.update("MOVIES", generos);
      self.filterProvider = providers.filter.list;
      self.generos = generos;
      self.tmpList = self.generos.slice(0, CANT_INICIAL);
      self.showFullLoader = false;
      self.changeCursor(false);

      // // console.log('Data for Providers', providers.filter.list);
      // this.$nextTick(() => {
      //     setTimeout(() => {
      //         self.$nav.init('movies');
      //     }, 150);
      //     self.$bus.$emit('show-loader', false);
      //     self.showFilters();
      // });
    },

    changeVariusGenre(name) {
      if (name === "Various") {
        return "Varios";
      }
      return name;
    },
    ClearFIlter() {
      let self = this;

      self.showFullLoader = true;

      self.changeCursor(true);

      if (self.filterGenres.length > 0) {
        self.tmpList = [];
        self.filterGenres.length = 0;
        self.filterGenres = [];
      }

      self.$nextTick(function () {
        self.tmpList = JSON.parse(JSON.stringify(self.filterGenres));

        if (self.tmpList.length <= 0) {
          self.tmpList = self.generos.slice(0, CANT_INICIAL);
          //Dejo de estar en "modo filtro de usuario"
          self.isFiltered = false;
        }

        setTimeout(function () {
          self.showFullLoader = false;

          self.changeCursor(false);
        }, 1000);
      });
    },

    changeCursor(show) {
      if (show) {
        document.documentElement.style.cursor = "wait";
      } else {
        document.documentElement.style.cursor = "default";
      }
    },

    onScroll() {
      //Si estoy filtrando, no tengo que agregar géneros nuevos en el scroll
      if (!this.isScroll && !this.isFiltered) {
        let self = this;

        let scrollY =
          window.scrollY === 0 ? document.body.scrollTop : window.scrollY;
        const scrollLoadOffset = 1000; //Este valor regula qué tan lejos del final del scroll empiezo a cargar un género nuevo

        if (
          window.innerHeight + scrollY >=
          self.dom.gridContainer.offsetHeight - scrollLoadOffset
        ) {
          this.isScroll = true;
          document.body.style.pointerEvents = "none";
          this.changeCursor(true);

          let pushCount = 0;
          let pushNext = function () {
            //Intento cargar de a 3 géneros a la vez:
            if (pushCount < 3) {
              pushCount++;

              self.pushGenre(self.generos[self.tmpList.length], pushNext);
            } else {
              self.isScroll = false;
              document.body.style.pointerEvents = "";
              self.changeCursor(false);
            }
          };

          pushNext();
        }
      }
    },

    pushGenre(item, onFinish) {
      if (!item) {
        onFinish(false);
        return;
      }

      let self = this;
      let exist = self.tmpList.find(function (tmpItem) {
        return tmpItem.idCatalog === item.idCatalog;
      });

      if (!exist) {
        if (item.movies) {
          self.tmpList.push(item);

          onFinish(true);
        } else {
          telecentro.tplay.core.peliculas.peliculasPorGenero(
            item.idCatalog,
            function (peliculas) {
              item.movies = peliculas;

              self.tmpList.push(item);

              onFinish(true);
            }
          );
        }
      } else {
        onFinish(false);
      }
    },

    findBy(list, value, column) {
      return list.filter(function (item) {
        let name = item[column].toUpperCase();
        return name.includes(value);
      });
    },

    addFilter(genero) {
      let self = this;

      //Con isFiltered inhibo que se pusheen géneros desde onScroll.
      //Antes se hacía este control con filterGenres.length, pero entre que
      //se entra a addFilter y se actualiza filterGenres puede haber scroll
      //(ya que esto se hace depende de pushGenre, que es asincrónica).
      self.isFiltered = true;
      self.showFullLoader = true;
      self.changeCursor(true);

      if (self.filterGenres.length <= 0) {
        //Empiezo a filtrar los géneros: reseteo la lista de los géneros que muestro,
        //así no tengo los que había agregado inicialmente ni en el scroll
        self.tmpList = [];
      }

      this.$nextTick(function () {
        self.pushGenre(genero, function (ret) {
          if (ret) {
            self.filterGenres.push(genero);
          }

          setTimeout(function () {
            self.showFullLoader = false;

            self.changeCursor(false);
          }, 1000);
        });
      });
    },

    deleteFilter(index) {
      let self = this;

      self.showFullLoader = true;

      self.changeCursor(true);

      if (self.filterGenres.length > 0) {
        self.tmpList = [];
      }

      self.filterGenres.splice(index, 1);

      self.$nextTick(function () {
        self.tmpList = JSON.parse(JSON.stringify(self.filterGenres));

        if (self.tmpList.length <= 0) {
          self.tmpList = self.generos.slice(0, CANT_INICIAL);
          //Dejo de estar en "modo filtro de usuario"
          self.isFiltered = false;
        }

        setTimeout(function () {
          self.showFullLoader = false;

          self.changeCursor(false);
        }, 1000);
      });
    },
    onSelectProvider(item) {
      const self = this;
      self.selectedProvider = item;
    },
    haveItems(items) {
      if (items) {
        if (items.length > 0) {
          return true;
        }
      }
      return false;
    },

    continueWatching() {
      const self = this;
      const optionalParams = {
        allAssets: "1",
        contentTypeFilter: "movie",
        watchingMax: 89,
        limit: 15,
        watchingMin: 1,
      };
      telecentro.tplay.core.biz.vod
        .continueWatching(optionalParams)
        .then((r) => {
          self.continueWatchingRail = r;
          self.continueWatchingRailTemp = self.continueWatchingRail;
        });
    },

    watchAgain() {
      const self = this;
      const optionalParams = {
        allAssets: "1",
        contentTypeFilter: "movie",
        watchingMin: 90,
        limit: 15,
      };
      telecentro.tplay.core.biz.vod.watchItAgain(optionalParams).then((r) => {
        self.watchAgainRail = r;
        self.watchAgainRailTemp = self.watchAgainRail;
      });
    },
  },

  beforeDestroy() {
    document.removeEventListener("scroll", this.onScroll, true);
    this.$bus.$off("delete-filter", this.ClearFIlter);
  },
};
</script>
